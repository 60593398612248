<template>
    <Layout>
        <PageHeader :title="title" :items="items"/>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="getPrescriptionTypeByStatus">

                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="form-group mb-3">
                                        <div class="row">
                                            <label>Prescription Status</label>
                                            <select class="form-control" v-model="prescriptionStatus" @change="getPrescriptionTypeByStatus" name="accountType" id="accountType" v-validate="'required'" >
                                                <option value="PENDING">PENDING</option>
                                                <option value="APPROVED">APPROVED</option>
                                                <option value="STARTED">STARTED</option>
                                                <option value="COMPLETED">COMPLETED</option>
                                                <option value="SUSPENDED">SUSPENDED</option>
                                            </select>
                                            <span v-show="errors.has('prescriptionStatus')" class="help text-danger">{{ errors.first('prescriptionStatus') }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2">
                                    <div class="form-group mb-3">
                                        <label ></label>
                                        <br />
                                        <button class="btn btn-primary btn-block"  type="submit" style="margin-top: 8px;" >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-body">
                        <div>
                            <lottie-loader :loading="showLoader" />
                        </div>
                        <p class="text-muted font-13 mb-4"></p>
                        <div class="row mb-md-2">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select
                                                v-model="perPage"
                                                size="sm"
                                                :options="pageOptions"
                                        ></b-form-select
                                        >&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <!-- Search -->
                            <div class="col-sm-12 col-md-6">
                                <div
                                        id="tickets-table_filter"
                                        class="dataTables_filter text-md-right"
                                >
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input
                                                v-model="filter"
                                                type="search"
                                                placeholder="Search..."
                                                class="form-control form-control-sm ml-2"
                                        ></b-form-input>
                                    </label>
                                </div>
                            </div>
                            <!-- End search -->
                        </div>
                        <!-- Table -->
                        <div class="table-responsive mb-0" id="table">
                            <b-table
                                    table-class="table table-centered w-100"
                                    thead-tr-class="bg-light"
                                    :items="prescriptionList"
                                    :fields="tableFields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn"
                                    @filtered="onFiltered"
                            >
                                <template #cell(name)="data">
                                    <span class="font-15 font-weight-bold text-dark"> {{ data.item.name}}</span>
                                </template>
                                <template v-slot:cell(status)="data">
                                      <span
                                              class="p-1 badge badge-soft-success"
                                              :class="{
                                          'badge-soft-danger':
                                            data.item.status === 'PENDING',
                                          'badge-soft-primary':
                                            data.item.status === 'ACTIVE',
                                             'badge-soft-secondary':
                                            data.item.status === 'COMPLETED',
                                        }">
                                        {{ data.item.status }}</span
                                      >
                                </template>
                                <template v-slot:cell(image)="data">
                                    <img
                                            v-if="data.item.image"
                                            :src="data.item.image"
                                            alt=""
                                            class="rounded mr-3"
                                            height="48"
                                    />
                                    <div
                                            v-if="!data.item.image"
                                            class="avatar-xs d-inline-block mr-2"
                                    >
                                    </div>

                                </template>

                                <template #cell(frequency)="data">
                                    <span class="badge badge-outline-secondary p-1"> {{ data.item.frequency}}</span>
                                </template>
                                <template #cell(route)="data">
                                    <span class="badge badge-outline-primary p-1"> {{ data.item.route}}</span>
                                </template>
                                <template #cell(duration)="data">
                                    <span class="badge badge-outline-danger p-1"> {{ data.item.duration}} days</span>
                                </template>

                                <template #cell(dateCreated)="data">
                                    <span class="font-12 font-weight-bold text-dark"> {{ data.item.dateCreated | moment("MMMM Do YYYY") }}</span>
                                </template>
                                <template #cell(actions)="data">
                                    <router-link class="btn btn-primary mr-2" style="cursor: pointer" :to="'/prescription/'+data.item.id"> <i class="mdi mdi-eye mx-1"></i>View</router-link>
                                </template>
                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div
                                        class="dataTables_paginate paging_simple_numbers float-right"
                                >
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination
                                                v-model="currentPage"
                                                :total-rows="rows"
                                                :per-page="perPage"
                                        ></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<script>
    import Layout from "../../layouts/main";
    import PageHeader from "@/components/Page-header";
    import BaseUrl from "../../../components/constants";
    import ToastConfigAlert from "../../../../ToastConfig";
    import LottieLoader from "../../../components/LottieLoader";

    export default {
        name: "ManagePrescriptions",
        components : {
            Layout,
            PageHeader,
            LottieLoader
        },
        data() {
            return {
                showLoader : false,
                prescriptionStatus:'',
                prescriptionList : [],
                title: "Manage prescription",
                items: [
                    {
                        text: "",
                    },
                    {
                        text: "Dashboards",
                    },
                    {
                        text: "prescription",
                        active: true,
                    },
                ],
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: null,
                filterOn: [],
                sortDesc: true,
                sortBy: "id",
                tableFields: [
                    {
                        thClass: 'd-none', tdClass: 'd-none',
                        key: "id",
                        sortable: true,
                    },
                    {
                        label:"Name",
                        key: "name",
                        sortable: true,
                    },
                    {
                        label:"Image",
                        key: "image",
                        sortable: true,
                    },
                    {
                        label:"Prescription type",
                        key: "type",
                        sortable: true,
                    },
                    {
                        label:"Phone number",
                        key: "phoneNumber",
                        sortable: true,
                    },
                    {
                        label:"Date added",
                        key: "dateCreated",
                        sortable: true,
                    },
                    {
                        key: "actions",
                        sortable: true,
                    },
                ],
            }
        },
        methods : {
            getPrescriptionTypeByStatus() {
                this.showLoader = true
                BaseUrl.AxiosBearer.get(`/admin/prescriptions?status=${this.prescriptionStatus}&page=1&pageSize=1000000`).then((resp) => {
                    this.showLoader = false
                    if(resp.data.status) {
                        this.prescriptionList = []
                    // console.log(" prescrition list : ", resp.data.payload)
                        resp.data.payload.forEach((data) => {
                            this.prescriptionList.push({
                                id : data.id,
                                name : data.patient?.identity?.surname + ' '+data.patient?.identity?.othernames,
                                image : data.prescriptionImage,
                                type : data.prescriptionType,
                                phoneNumber : data.patient?.identity?.phoneNumber,
                                dateCreated : data.createdAt
                            })
                        })
                    }

                }).catch((error) => {
                    this.$bvToast.toast(error, ToastConfigAlert)
                })
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
        },
        created() {
           this.prescriptionStatus = 'PENDING'
            this.getPrescriptionTypeByStatus()
        },
        computed: {
            rows() {
                return this.prescriptionList.length;
            },
        },
    }
</script>

<style scoped>

</style>